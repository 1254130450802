import React from "react";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import styles from "./projects.module.scss";

const Project = props => (
    <div className={styles.project}>
        <div className={styles.title}>
            <h3 className={styles.text}>{props.title}</h3>
            <br />
            <h5 className={styles.desc}>{props.date}</h5>
            <div className={styles.link}>
                <a href={props.link} target="_blank" rel="noreferrer">
                    <OpenInNewIcon
                        style={{ fontSize: ".9rem", color: "#4CAF50" }}
                    />
                </a>
            </div>
        </div>
        <p className={styles.projectInfo}>{props.info}</p>
    </div>
);

const Projects = () => (
    <div className={styles.content}>
        {allProjects.map(project => {
            return (
                <Project
                    key={project.title}
                    title={project.title}
                    date={project.date}
                    info={project.info}
                    link={project.link}
                />
            );
        })}
    </div>
);

const allProjects = [
    {
        title: "PhotoSmyth",
        date: "March 2024",
        info: "A generative AI application capable of rapid on-demand image synthesis",
        link: "https://prikshit.dev/projects/photosmyth/"
    },
    {
        title: "Mausam",
        date: "May 2023",
        info: "Visualized 150 years of Earth's climate data, offering in-depth trend insights to users",
        link: "https://prikshit.dev/projects/mausam"
    },
    // {
    //     title: "Surveys",
    //     date: "November, 2020",
    //     info:
    //         "A Countly module that allows you to run surveys based on in-app behaviour & properties of your users",
    //     link: "https://count.ly/plugins/surveys"
    // },
    // {
    //     title: "NPS®",
    //     date: "November, 2020",
    //     info:
    //         "A Countly module that allows you to measure your customer experience using Net Promoter Score® surveys",
    //     link: "https://count.ly/plugins/nps"
    // },
    // {
    //     title: "Performance monitoring",
    //     date: "June, 2020",
    //     info:
    //         "A Countly module that monitors the performance of your application",
    //     link: "https://count.ly/plugins/performance-monitoring"
    // },
    // {
    //     title: "A/B testing",
    //     date: "September, 2019",
    //     info: "A Countly module that controls your app from the server",
    //     link: "https://count.ly/plugins/ab-testing"
    // },
    // {
    //     title: "Remote config",
    //     date: "March, 2019",
    //     info: "A Countly module that controls your app from the server",
    //     link: "https://count.ly/plugins/remote-config"
    // },
    {
        title: "Log-err",
        date: "March, 2017",
        info: "A lightweight logging module for nodejs",
        link: "https://www.npmjs.com/package/log-err"
    },
    {
        title: "Card sorting game",
        date: "May, 2016",
        info: "Learn sorting algorithms by shuffling cards",
        link: "https://prikshit.dev/projects/dgbl/index.html"
    },
    {
        title: "EHR system",
        date: "December, 2015",
        info: "Electronic health record system for health centers",
        link: "https://prikshit.dev/projects/ehr/index.php"
    },
    {
        title: "Nimbus 2015",
        date: "March, 2015",
        info: "Website for annual technical festival at NIT Hamirpur",
        link: "https://prikshit.dev/projects/nimbus/index.php"
    },
    {
        title: "Feedback system",
        date: "December, 2014",
        info: "An online feedback system for an education institution",
        link: "https://prikshit.dev/projects/feedback/index.php"
    },
    {
        title: "Hill'ffair 2014",
        date: "November, 2014",
        info: "Website for annual cultural festival at NIT Hamirpur",
        link: "https://prikshit.dev/projects/hillffair/index.php"
    }
];

export default Projects;
