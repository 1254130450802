import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Bio from "../components/Bio";
import Projects from "../components/Projects";

export default function Project() {
    return (
        <Layout>
            <Seo
                title="Projects"
                keywords={[`prikshit`, `tekta`, `portfolio`, `projects`]}
            />
            <Bio />
            <Projects />
        </Layout>
    );
}
